import React, {Fragment, useRef, useEffect, useState} from 'react';
import { useTranslation} from 'react-i18next';
import { MapContainer, TileLayer, Marker } from 'react-leaflet'
import L from 'leaflet';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

const ProjectInfoContainer = ({ project_obj, project_address }) => {
	const {t} = useTranslation();
	
	const prev_coords = useRef(null)
	const [coords, set_coords] = useState(null)
	
	const map_ref = useRef(null)
	const default_zoom = useRef(13)
	
	useEffect(() => {
		let temp_coords = null
		if(project_address && Object.keys(project_address).length > 0) {
			if(project_address['location_coords'] !== undefined && project_address['location_coords'].length > 0) {
				temp_coords = [project_address['location_coords'][1], project_address['location_coords'][0]]
			}
		}
		if(prev_coords.current === null && coords === null && temp_coords !== null) {
			set_coords(temp_coords)
		}
		if(JSON.stringify(prev_coords.current) !== JSON.stringify(temp_coords)) {
			prev_coords.current = coords
			set_coords(temp_coords)
			if(map_ref.current !== null) {
				map_ref.current.setView(coords, map_ref.current.getZoom());
			}
		}
	}, [project_address, map_ref, coords, prev_coords]);
	
	let addr_info_jsx = []
	let addr_jsx = []
	if(project_address.street !== '') {
		addr_jsx.push(<Fragment key={`street-${project_obj.slug}`}>>{`${project_address.street}`}<br /></Fragment>)
	}
	addr_jsx.push(<Fragment key={`zip-city-${project_obj.slug}`}>{`${project_address.zip} ${project_address.city}`}<br /></Fragment>)
	addr_jsx.push(<Fragment key={`country-${project_obj.slug}`}>{`${project_address.country_display}`}</Fragment>)
	// is there an address
	console.log('coords: ', coords)
	if(coords !== null) {
		addr_info_jsx = [
			<Fragment key={`addr-info-${project_obj.slug}`}>
				<div key={`addr-title-${project_obj.slug}`} className="title">{t('Adresse')}</div>
				<p key={`addr-${project_obj.slug}`}>
					{addr_jsx}
				</p>
				<div key={`map-${project_obj.slug}`} className="map-container">
					<MapContainer center={coords} zoom={default_zoom.current} whenCreated={ mapInstance => { map_ref.current = mapInstance } }>
						<TileLayer
						    attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
						    url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
						  />
						  <Marker position={coords} />
					</MapContainer>
				</div>
			</Fragment>
		]
	}
	
	return (
		<div className="project-info-container">
			<div className="title">{t('Projekt Nummer/ID')}</div>
			<p>{project_obj.project_id}</p>
			<div className="title">{t('Beschreibung')}</div>
			<p>{project_obj.description}</p>
			{addr_info_jsx}
		</div>
	)
}

export default ProjectInfoContainer;
